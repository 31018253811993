import {
  Route,
  Routes,
  BrowserRouter,
  useLocation,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.min.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./App.css";
import Login from "./components/loginPage/login";
import UserForm from "./components/Admin/admin-dashboard/user-list/UserForm";
import UsersList from "./components/Admin/admin-dashboard/user-list/UserList";
import AdminDashboardLayout from "./layout/AdminDashboardLayout";
import OutletsList from "./components/Admin/admin-dashboard/outlet-list/OutletList";
import UpdateOutlet from "./components/Admin/admin-dashboard/outlet-list/UpdateOutletForm";
import { Provider, defaultTheme } from "@adobe/react-spectrum";
import UpdateCallType from "./components/Admin/admin-dashboard/call-type-list/UpdateCallTypeForm";
import CallsList from "./components/Admin/admin-dashboard/calls-list/CallsList";
import AddCallForm from "./components/Admin/admin-dashboard/calls-list/AddCallForm";
import UpdateCall from "./components/Admin/admin-dashboard/calls-list/UpdateCallForm";
import RolesList from "./components/Admin/admin-dashboard/roles-list/RolesList";
import AddRoleForm from "./components/Admin/admin-dashboard/roles-list/AddRoleForm";
import CountriesPage from "./components/Admin/admin-dashboard/countries-page/CountriesPage";
import Samsung from "./components/Admin/admin-dashboard/samsung/samsung";
import SalesChatbot from "./components/Admin/admin-dashboard/vodacom/sales-chatbot/sales-chatbot";
import AccountsPage from "./components/Admin/admin-dashboard/accounts-page/AccountsPage";
import JobRolesPage from "./components/Admin/admin-dashboard/job-roles-page/JobRolesPage";
import RegionsPage from "./components/Admin/admin-dashboard/regions-page/RegionsPage";
import ChannelsPage from "./components/Admin/admin-dashboard/channels-page/ChannelsPage";
import HierarchyPage from "./components/Admin/admin-dashboard/hierarchy-page/HierarchyPage";
import ScorecardsPage from "./components/Admin/admin-dashboard/scorecards/ScorecardsPage";
import ProjectsPage from "./components/Admin/admin-dashboard/projects-page/ProjectsPage";
import { createContext, useContext, useEffect, useState } from "react";
import EventEdit from "./components/Admin/admin-dashboard/events-page/events-edit/EventEdit";
import CyclesPage from "./components/Admin/admin-dashboard/cycles-page/CyclesPage";
import SimpleBots from "./components/SimpleBots/SimpleBots";
import RedFlagsPage from "./components/Admin/admin-dashboard/red-flags-page/RedFlagsPage";
import RedFlagsEdit from "./components/Admin/admin-dashboard/red-flags-page/RedFlagsEdit";
import ProductsPage from "./components/Admin/admin-dashboard/products-page/ProductsPage";
import ProductDetailsPage from "./components/Admin/admin-dashboard/products-page/ProductDetailsPage";
import CycleDetailsPage from "./components/Admin/admin-dashboard/cycles-page/CycleDetailsPage";
import SkuBasketList from "./components/Admin/admin-dashboard/sku-basket-list/SkuBasketList";
import SkuBasketForm from "./components/Admin/admin-dashboard/sku-basket-list/SkuBasketForm";
import CallTypesList from "./components/Admin/admin-dashboard/call-type-list/CallTypesList";
import ReportsPage from "./components/Admin/admin-dashboard/reports-page/ReportsPage";
import PosBasketList from "./components/Admin/admin-dashboard/pos-basket-list/PosBasketList";
import PosBasketForm from "./components/Admin/admin-dashboard/pos-basket-list/PosBasketForm";
import ScorecardsForm from "./components/Admin/admin-dashboard/scorecards/ScorecardsForm";
import OutletResearchPage from "./components/Admin/admin-dashboard/outlet-research-page/OutletResearchPage";
import OutletResearchForm from "./components/Admin/admin-dashboard/outlet-research-page/OutletResearchForm";
import StrategicAudits from "./components/Admin/admin-dashboard/cluster-specialists/StrategicAudits";
import StrategicAuditForm from "./components/Admin/admin-dashboard/cluster-specialists/StrategicAuditForm";
import RedFlagsSettings from "./components/Admin/admin-dashboard/red-flags-settings/RedFlagsSettings";
import StrategicAuditView from "./components/Admin/admin-dashboard/cluster-specialists/StrategicAuditView";
import * as S from "./App.styled";
import Maps from "./components/Admin/admin-dashboard/maps/Maps";
import DiaryImportPage from "./components/Admin/admin-dashboard/diary-import/DiaryImportPage";
import DiaryManagement from "./components/Admin/training/DiaryManagement/DiaryManagement";
import StoreFinder from "./components/Admin/training/StoreFinder/StoreFinder";
import CoachingCalendar from "./components/Admin/training/CoachingCalendar/CoachingCalendar";
import CallDetailsPage from "./components/Admin/training/CallDetailsPage/CallDetailsPage";
import { User, getAuth, onAuthStateChanged } from "firebase/auth";
import "./config/firebase";
// import { AuthContext, AuthProvider } from "./Auth";
import WithPrivateRoute from "./utils/WithPrivateRoutes";
import DelegatesList from "./components/Admin/admin-dashboard/delegates-page/DelegatesListPage";
import DelegateForm from "./components/Admin/admin-dashboard/delegates-page/DelegateForm";
import KnowledgeTransferPage from "./components/Admin/admin-dashboard/knowledge-transer-page/KnowledgeTransferPage";
import KnowledgeTransferForm from "./components/Admin/admin-dashboard/knowledge-transfer-form/KnowledgeTransferForm";
import MediaListPage from "./components/Admin/admin-dashboard/media-page/MediaListPage";
import MediaUpload from "./components/Admin/admin-dashboard/media-page/MediaUpload";
import DelegatesTransferList from "./components/Admin/admin-dashboard/delegates-page/DelegatesTransferPage";
import GPSCorrectionsPage from "./components/Admin/admin-dashboard/gps-corrections-page/GPSCorrectionsPage";
import MediaEditForm from "./components/Admin/admin-dashboard/media-page/MediaEditForm";
import KTModulesCreation from "./components/Admin/admin-dashboard/kt-modules/KTModulesCreation";
import CitiesList from "./components/Admin/admin-dashboard/cities/CitiesList";
import CityForm from "./components/Admin/admin-dashboard/cities/CityForm";
import TrainingVenuesList from "./components/Admin/admin-dashboard/training-venues/TrainingVenuesList";
import TrainingVenueDetails from "./components/Admin/admin-dashboard/training-venues/TrainingVenueDetails";
import RequestsPage from "./components/Admin/admin-dashboard/smart-start-pages/RequestsPage";
import Dashboard from "./components/TVDashboard/Dashboard";
import FormalTrainingEventsList from "./components/Admin/admin-dashboard/formal-training-events-list/FormalTrainingEventsList";
import FormalTrainingForm from "./components/Admin/admin-dashboard/formal-training-events-list/FormalTraining";
import KTModulePublicQuestionnaire from "./components/Admin/admin-dashboard/kt-modules/KTModulePublicQuestionnaire";
import FormalTrainingView from "./components/Admin/admin-dashboard/formal-training-events-list/FormalTrainingView";
import ContentManagement from "./components/Admin/admin-dashboard/content-management/ContentManagement";
import PhoneNumbersPage from "./components/Admin/admin-dashboard/phone-numbers-page/PhoneNumbersPage";
import PhoneNumbersForm from "./components/Admin/admin-dashboard/phone-numbers-page/PhoneNumbersForm";
import ProductSettingsPage from "./components/Admin/admin-dashboard/product-settings-page/ProductSettingsPage";
import TargetsImportPage from "./components/Admin/admin-dashboard/targets-imports-page/TargetsImportPage";
import InteractionSettingsPage from "./components/Admin/admin-dashboard/interaction-settings-page/InteractionSettingsPage";
import SupportPage from "./components/Admin/admin-dashboard/support-page/SupportPage";
import SupportTicketForm from "./components/Admin/admin-dashboard/support-page/SupportTicketForm";
import PhotoAuditsPage from "./components/Admin/admin-dashboard/photo-audits/PhotoAuditsPage";
import SupportTicketDetails from "./components/Admin/admin-dashboard/support-page/SupportTicketDetails";
import OutletImportPage from "./components/Admin/admin-dashboard/outlet-import/OutletImportsPage";
import PhotoAuditForm from "./components/Admin/admin-dashboard/photo-audits/PhotoAuditForm";
import PhotoAuditView from "./components/Admin/admin-dashboard/photo-audits/PhotoAuditView";
import NewsfeedPage from "./components/Admin/admin-dashboard/newsfeed-page/NewsfeedPage";
import SchoolImports from "./components/Admin/admin-dashboard/custom-requests/SchoolImports";
import TimesheetsWorkTypesList from "./components/Admin/admin-dashboard/timesheets/TimesheetsWorkTypesList";
import TimesheetsWorkTypesForm from "./components/Admin/admin-dashboard/timesheets/TimesheetsWorkTypesForm";
import AuthContext from "./store/auth/AuthContextProvider";
import DevicesListPage from "./components/Admin/admin-dashboard/devices-list-page/DevicesListPages";
import { instance } from "./api/common-api";
import useApiPost from "./hooks/useApiPost";
import ChannelsList from "./components/Admin/admin-dashboard/channels/ChannelsList";
import ChannelForm from "./components/Admin/admin-dashboard/channels/ChannelForm";
import ChainsList from "./components/Admin/admin-dashboard/chains/ChainsList";
import ChainsForm from "./components/Admin/admin-dashboard/chains/ChainsForm";
import AccessPage from "./components/Admin/admin-dashboard/access/AccessPage";
import SystemRolesPage from "./components/Admin/admin-dashboard/access/SystemRolesPage";
import SystemRoleForm from "./components/Admin/admin-dashboard/access/SystemRoleForm";
import AxonifyPage from "./components/Admin/admin-dashboard/axonify/AxonifyPage";
import AxonifyForm from "./components/Admin/admin-dashboard/axonify/AxonifyForm";
import AxonifyDetail from "./components/Admin/admin-dashboard/axonify/AxonifyDetail";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import PasswordNotifyContainer from "./common/PasswordNotifyModal/PasswordNotifyContainer";
import ProjectForm from "./components/Admin/admin-dashboard/projects-page/ProjectForm";
import GradingList from "./components/Admin/admin-dashboard/grading/GradingList";
import GradingForm from "./components/Admin/admin-dashboard/grading/GradingForm";
import OutletTypeList from "./components/Admin/admin-dashboard/outlet-type/OutletTypeList";
import OutletTypeForm from "./components/Admin/admin-dashboard/outlet-type/OutletTypeForm";
import AddBulkMappingForm from "./components/Admin/admin-dashboard/outlet-list/AddBulkMappingForm";
import DropOffPage from "./components/Admin/admin-dashboard/dropoff/DropOffPage";
import GeographicalHierarchyHome from "./components/Admin/admin-dashboard/geographical-hierarchy/GeographicalHierarchyHome";
import AddBulkCallFrequencyMappingForm from "./components/Admin/admin-dashboard/outlet-list/AddBulkCallFrequencyMappingForm";
import OutletState from "./components/Admin/admin-dashboard/outlet-state/OutletState";
import Client from "./components/Admin/admin-dashboard/clients/ClientList";
import ClientForm from "./components/Admin/admin-dashboard/clients/ClientForm";
import OutletStateForm from "./components/Admin/admin-dashboard/outlet-state/OutletStateForm";


export interface IUserData {
  sub: string;
  name: string;
  family_name: string;
  given_name: string;
  picture: string;
  email: string;
  test: any;
  groups: string[];
}

export interface ICreateUserData {
  externalId: string;
  name: string;
  surname: string;
  email: string;
  test: any;
}

export async function getSession(request: Request) {
  const cookie = request.headers.get("Cookie");
  return sessionStorage.getSession(cookie);
}

export const AppContext = createContext({
  projectId: "2c8887d0-9d21-40af-918c-2df3fa15b9a5",
  userRole: "user",
  setProject: (_value: string) => { },
  setUserRole: (_value: string) => { },
});

const Wrapper = (props: any) => {
  const { children } = props;
  return <>{children}</>;
};

const App = (props: any) => {
  const auth = getAuth();
  const { userRole, setUserRole } = useContext(AppContext);
  const { authState } = useContext(AuthContext);
  const { request } = useApiPost();
  const { isLoading, access } = authState;
  const [projectId, setProject] = useState(
    "2c8887d0-9d21-40af-918c-2df3fa15b9a5"
  );

  const value = { projectId, setProject, userRole, setUserRole };
  const { globalLogInDispatch, setLoading, globalLogOutDispatch } =
    useContext(AuthContext);

  const hasAccess = (requiredAccess: string) => access.includes(requiredAccess);
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      handleGetToken(user);
    });
  }, []);
  const handleGetToken = async (user: User | null) => {
    if (user) {
      const token = await user.getIdToken();

      instance.defaults.headers.common["Authorization"] = token;
      localStorage.setItem('AuthToken', `${token}`);
      const currentDate = new Date();
      localStorage.setItem('TokenTime', `${currentDate}`);
      try {
        const authResponse = await request("/office/auth/sessionLogin", "POST");

        const {
          user: data,
          access,
          projectAccess,
          passwordAgeInDays,
          forcePassNotify,
          passNotify,
          name
        } = authResponse;

        globalLogInDispatch({
          uid: "-none1-" + token,
          email: data?.email,
          role: data?.role,
          access,
          token,
          projectAccess,
          passwordAgeInDays,
          forcePassNotify,
          passNotify,
          name
        });
      } catch (error) {
        globalLogOutDispatch();
      }
    } else {
      console.log("user is logged out");
      setLoading(false);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Wrapper>
      <Provider theme={defaultTheme} locale={"en-EN"} height={"100%"}>
        <AppContext.Provider value={value}>
          <ToastContainer />
          <PasswordNotifyContainer />
          <S.Main>
            <S.MainContainer>
              <S.SwitchContainer>
                <Routes>
                  {!authState?.isLoggedIn && (
                    <>
                      <Route path="*" element={<Login />} />
                    </>
                  )}
                  {authState && authState.isLoggedIn && (
                    <>
                      <Route path="/login" element={<Login />} />
                      <Route path="/" element={<AdminDashboardLayout />} />
                      <Route
                        path="dashboard"
                        element={
                          <WithPrivateRoute requiredRole="CBU Dashboard" />
                        }
                      >
                        <Route path="" element={<AdminDashboardLayout />}>
                          <Route
                            path="/dashboard/tv-dashboard"
                            element={<Dashboard />}
                          />
                        </Route>
                      </Route>

                      <Route
                        path="admin"
                        element={<WithPrivateRoute requiredRole="admin" />}
                      >
                        <Route path="" element={<AdminDashboardLayout />}>
                          {/* <Route
                              path="/admin/reports"
                              element={<ReportsPage />}
                            /> */}
                          {hasAccess("Calls Manager") && (
                            <Route
                              path="/admin/call-details/:visitId"
                              element={<CallDetailsPage />}
                            />
                          )}

                          {hasAccess("Timesheets WorkType") && (
                            <Route
                              path="/admin/timesheets-worktypes"
                              element={<TimesheetsWorkTypesList />}
                            />
                          )}

                          {hasAccess("Timesheets WorkType") && (
                            <Route
                              path="/admin/timesheets-worktypes/new"
                              element={<TimesheetsWorkTypesForm />}
                            />
                          )}

                          {hasAccess("Timesheets WorkType") && (
                            <Route
                              path="/admin/timesheets-worktypes/edit/:workTypeId"
                              element={<TimesheetsWorkTypesForm />}
                            />
                          )}

                          {hasAccess("Smart Start") && (
                            <Route
                              path="/admin/smart-start"
                              element={<RequestsPage />}
                            />
                          )}

                          {hasAccess("Photo Audit") && (
                            <Route
                              path="/admin/photo-audit"
                              element={<PhotoAuditsPage />}
                            />
                          )}
                          {hasAccess("Photo Audit") && (
                            <Route
                              path="/admin/photo-audit/new"
                              element={<PhotoAuditForm />}
                            />
                          )}
                          {hasAccess("Photo Audit") && (
                            <Route
                              path="/admin/photo-audit/edit/:auditId"
                              element={<PhotoAuditView />}
                            />
                          )}

                          {hasAccess("Axonify") && (
                            <Route
                              path="/admin/axonify"
                              element={<AxonifyPage />}
                            />
                          )}
                          {hasAccess("Axonify") && (
                            <Route
                              path="/admin/axonify/new"
                              element={<AxonifyForm />}
                            />
                          )}
                          {hasAccess("Axonify") && (
                            <Route
                              path="/admin/axonify/detail/:axonyId"
                              element={<AxonifyDetail />}
                            />
                          )}

                          {hasAccess("Outlets Imports") && (
                            <Route
                              path="/admin/outlets-import"
                              element={<OutletImportPage />}
                            />
                          )}
                          {hasAccess("Devices") && (
                            <Route
                              path="/admin/devices"
                              element={<DevicesListPage />}
                            />
                          )}

                          {hasAccess("Newsfeed") && (
                            <Route
                              path="/admin/newsfeed"
                              element={<NewsfeedPage />}
                            />
                          )}

                          {hasAccess("Tickets") && (
                            <Route
                              path="/admin/support/tickets"
                              element={<SupportPage />}
                            />
                          )}
                          {hasAccess("Tickets") && (
                            <Route
                              path="/admin/support/tickets/new"
                              element={<SupportTicketForm />}
                            />
                          )}
                          {hasAccess("Cities") && (
                            <Route
                              path="/admin/support/tickets/:ticketId"
                              element={<SupportTicketDetails />}
                            />
                          )}

                          {hasAccess("Interaction Settings") && (
                            <Route
                              path="/admin/interaction-settings"
                              element={<InteractionSettingsPage />}
                            />
                          )}

                          {hasAccess("Content Management") && (
                            <Route
                              path="/admin/content-management"
                              element={<ContentManagement />}
                            />
                          )}
                          {hasAccess("Cities") && (
                            <Route
                              path="/admin/cities"
                              element={<CitiesList />}
                            />
                          )}

                          {hasAccess("Cities") && (
                            <Route
                              path="/admin/city/new"
                              element={<CityForm />}
                            />
                          )}
                          {hasAccess("Cities") && (
                            <Route
                              path="/admin/city/edit/:cityId"
                              element={<CityForm />}
                            />
                          )}
                          {hasAccess("Channels") && (
                            <Route
                              path="/admin/channels"
                              element={<ChannelsList />}
                            />
                          )}
                          {hasAccess("Channels") && (
                            <Route
                              path="/admin/channel/new"
                              element={<ChannelForm />}
                            />
                          )}
                          {hasAccess("Channels") && (
                            <Route
                              path="/admin/channel/edit/:channelId"
                              element={<ChannelForm />}
                            />
                          )}
                          {hasAccess("Chains") && (
                            <Route
                              path="/admin/chains"
                              element={<ChainsList />}
                            />
                          )}
                          {hasAccess("Chains") && (
                            <Route
                              path="/admin/chain/new"
                              element={<ChainsForm />}
                            />
                          )}
                          {hasAccess("Chains") && (
                            <Route
                              path="/admin/chain/edit/:chainId"
                              element={<ChainsForm />}
                            />
                          )}
                          {hasAccess("Access") && (
                            <Route
                              path="/admin/access"
                              element={<AccessPage />}
                            />
                          )}
                          {hasAccess("Access Roles") && (
                            <Route
                              path="/admin/system-roles"
                              element={<SystemRolesPage />}
                            />
                          )}
                          {hasAccess("Access Roles") && (
                            <Route
                              path="/admin/system-roles/new"
                              element={<SystemRoleForm />}
                            />
                          )}
                          {hasAccess("Access Roles") && (
                            <Route
                              path="/admin/system-role/edit/:roleId"
                              element={<SystemRoleForm />}
                            />
                          )}

                          {hasAccess("Training Venues") && (
                            <Route
                              path="/admin/training-venues"
                              element={<TrainingVenuesList />}
                            />
                          )}
                          {hasAccess("Training Venues") && (
                            <Route
                              path="/admin/training-venue/new"
                              element={<TrainingVenueDetails />}
                            />
                          )}
                          {hasAccess("Training Venues") && (
                            <Route
                              path="/admin/training-venue/edit/:venueId"
                              element={<TrainingVenueDetails />}
                            />
                          )}

                          {hasAccess("Store Finder") && (
                            <Route
                              path="/admin/store-finder"
                              element={<StoreFinder />}
                            />
                          )}

                          {hasAccess("Manage Diary") && (
                            <Route
                              path="/admin/diary-management"
                              element={<DiaryManagement />}
                            />
                          )}
                          {hasAccess("Formal Trainings") && (
                            <Route
                              path="/admin/formal-trainings"
                              element={<FormalTrainingEventsList />}
                            />
                          )}
                          {hasAccess("Formal Trainings") && (
                            <Route
                              path="/admin/formal-trainings/new"
                              element={<FormalTrainingForm />}
                            />
                          )}
                          {hasAccess("Formal Trainings") && (
                            <Route
                              path="/admin/formal-trainings/edit/:eventId"
                              element={<FormalTrainingForm />}
                            />
                          )}
                          {hasAccess("Coach Calendar") && (
                            <Route
                              path="/admin/formal-trainings/view/:eventId"
                              element={<FormalTrainingView />}
                            />
                          )}
                          {hasAccess("Coach Calendar") && (
                            <Route
                              path="/admin/coaching-calendar"
                              element={<CoachingCalendar />}
                            />
                          )}
                          {hasAccess("Users") && (
                            <Route
                              path="/admin/users"
                              element={<UsersList />}
                            />
                          )}
                          {hasAccess("Reports") && (
                            <Route
                              path="/admin/reports"
                              element={<ReportsPage />}
                            />
                          )}
                          {hasAccess("Maps") && (
                            <Route path="/admin/map" element={<Maps />} />
                          )}
                          {hasAccess("Dashboard") && (
                            <Route
                              path="/admin/tv-dashboard"
                              element={<Dashboard />}
                            />
                          )}
                          {hasAccess("Delegates") && (
                            <Route
                              path="/admin/delegates"
                              element={<DelegatesList />}
                            />
                          )}
                          {hasAccess("Delegates") && (
                            <Route
                              path="/admin/delegate-transfers"
                              element={<DelegatesTransferList />}
                            />
                          )}
                          {hasAccess("GPS Corrections") && (
                            <Route
                              path="/admin/gps-corrections"
                              element={<GPSCorrectionsPage />}
                            />
                          )}
                          {hasAccess("Delegates") && (
                            <Route
                              path="/admin/delegates/new"
                              element={<DelegateForm />}
                            />
                          )}
                          {hasAccess("Delegates") && (
                            <Route
                              path="/admin/delegates/edit/:delegateId"
                              element={<DelegateForm />}
                            />
                          )}
                          {hasAccess("Users") && (
                            <Route
                              path="/admin/user/new"
                              element={<UserForm />}
                            />
                          )}
                          {hasAccess("Red Flags Settings") && (
                            <Route
                              path="/admin/settings/red-flags"
                              element={<RedFlagsSettings />}
                            />
                          )}
                          {hasAccess("Users") && (
                            <Route
                              path="/admin/user/edit/:userId"
                              element={<UserForm />}
                            />
                          )}
                          {hasAccess("Products") && (
                            <Route
                              path="/admin/products"
                              element={<ProductsPage />}
                            />
                          )}
                          {hasAccess("Product Settings") && (
                            <Route
                              path="/admin/product-settings"
                              element={<ProductSettingsPage />}
                            />
                          )}
                          {hasAccess("Products") && (
                            <Route
                              path="/admin/product/edit/:productId"
                              element={<ProductDetailsPage />}
                            />
                          )}
                          {hasAccess("Products") && (
                            <Route
                              path="/admin/product/new"
                              element={<ProductDetailsPage />}
                            />
                          )}
                          {hasAccess("Cycles") && (
                            <Route
                              path="/admin/cycles"
                              element={<CyclesPage />}
                            />
                          )}
                          {hasAccess("Cycles") && (
                            <Route
                              path="/admin/cycle/edit/:cycleId"
                              element={<CycleDetailsPage />}
                            />
                          )}
                          {hasAccess("Cycles") && (
                            <Route
                              path="/admin/cycle/new"
                              element={<CycleDetailsPage />}
                            />
                          )}
                          {hasAccess("Roles") && (
                            <Route
                              path="/admin/roles"
                              element={<RolesList />}
                            />
                          )}
                          {hasAccess("Users") && (
                            <Route
                              path="/admin/accounts"
                              element={<AccountsPage />}
                            />
                          )}
                          {hasAccess("Channels") && (
                            <Route
                              path="/admin/channels"
                              element={<ChannelsPage />}
                            />
                          )}
                          {hasAccess("Heads Up") && (
                            <Route
                              path="/admin/heads-up"
                              element={<EventEdit />}
                            />
                          )}
                          {/* {hasAccess("Countries") && (
                            <Route
                              path="/admin/countries"
                              element={<CountriesPage />}
                            />
                          )} */}
                          {hasAccess("Outlets") && (
                            <Route
                              path="/admin/outlets"
                              element={<OutletsList />}
                            />
                          )}
                          {hasAccess("Call Types") && (
                            <Route
                              path="/admin/call-types"
                              element={<CallTypesList />}
                            />
                          )}
                          {hasAccess("Call Types") && (
                            <Route
                              path="/admin/call-type/view/:callTypeId"
                              element={<UpdateCallType />}
                            />
                          )}
                          {hasAccess("Call Types") && (
                            <Route
                              path="/admin/call-type/new"
                              element={<UpdateCallType />}
                            />
                          )}
                          {hasAccess("Media Files") && (
                            <Route
                              path="/admin/media-files"
                              element={<MediaListPage />}
                            />
                          )}
                          {hasAccess("Media Files") && (
                            <Route
                              path="/admin/media-files/new"
                              element={<MediaUpload />}
                            />
                          )}
                          {hasAccess("Media Files") && (
                            <Route
                              path="/admin/media-files/edit/:mediaId"
                              element={<MediaEditForm />}
                            />
                          )}
                          {hasAccess("Phone Numbers") && (
                            <Route
                              path="/admin/phone-numbers"
                              element={<PhoneNumbersPage />}
                            />
                          )}
                          {hasAccess("Phone Numbers") && (
                            <Route
                              path="/admin/phone-numbers/new"
                              element={<PhoneNumbersForm />}
                            />
                          )}
                          {hasAccess("Phone Numbers") && (
                            <Route
                              path="/admin/phone-numbers/edit/:id"
                              element={<PhoneNumbersForm />}
                            />
                          )}
                          {hasAccess("Red Flags") && (
                            <Route
                              path="/admin/red-flags"
                              element={<RedFlagsPage />}
                            />
                          )}
                          {hasAccess("Red Flags") && (
                            <Route
                              path="/admin/red-flags/edit/:id"
                              element={<RedFlagsEdit />}
                            />
                          )}
                          {hasAccess("Roles") && (
                            <Route
                              path="/admin/job-roles"
                              element={<JobRolesPage />}
                            />
                          )}
                          {hasAccess("Scorecards") && (
                            <Route
                              path="/admin/scorecards"
                              element={<ScorecardsPage />}
                            />
                          )}
                          {hasAccess("Strategic Audits") && (
                            <Route
                              path="/admin/strategic-audit"
                              element={<StrategicAudits />}
                            />
                          )}
                          {hasAccess("Strategic Audits") && (
                            <Route
                              path="/admin/strategic-audit/new"
                              element={<StrategicAuditForm />}
                            />
                          )}
                          {hasAccess("Strategic Audits") && (
                            <Route
                              path="/admin/strategic-audit/edit/:auditId"
                              element={<StrategicAuditView />}
                            />
                          )}
                          {hasAccess("Strategic Audits") && (
                            <Route
                              path="/admin/strategic-audit/edit/draft/:draftId"
                              element={<StrategicAuditView />}
                            />
                          )}
                          {hasAccess("Scorecards") && (
                            <Route
                              path="/admin/scorecards/new"
                              element={<ScorecardsForm />}
                            />
                          )}
                          {hasAccess("Scorecards") && (
                            <Route
                              path="/admin/scorecards/:scorecardId"
                              element={<ScorecardsForm />}
                            />
                          )}
                          {hasAccess("Outlets") && (
                            <Route
                              path="/admin/outlet/view/:outletId"
                              element={<UpdateOutlet />}
                            />
                          )}
                          {hasAccess("Outlets") && (
                            <Route
                              path="/admin/outlet/new"
                              element={<UpdateOutlet />}
                            />
                          )}
                          {hasAccess("Outlets") && (
                            <Route
                              path="/admin/outlet/bulkMapping"
                              element={<AddBulkMappingForm />}
                            />
                          )}
                          {hasAccess("Outlets") && (
                            <Route
                              path="/admin/outlet/bulkCallFrequencyMapping"
                              element={<AddBulkCallFrequencyMappingForm />}
                            />
                          )}
                          {hasAccess("Calls Manager") && (
                            <Route
                              path="/admin/calls"
                              element={<CallsList />}
                            />
                          )}
                          {hasAccess("Calls Manager") && (
                            <Route
                              path="/admin/diary/:userId"
                              element={<AddCallForm />}
                            />
                          )}
                          {hasAccess("SKU Baskets") && (
                            <Route
                              path="/admin/sku-baskets"
                              element={<SkuBasketList />}
                            />
                          )}
                          {hasAccess("SKU Baskets") && (
                            <Route
                              path="/admin/sku-basket/new"
                              element={<SkuBasketForm />}
                            />
                          )}
                          {hasAccess("SKU Baskets") && (
                            <Route
                              path="/admin/sku-basket/view/:basketId"
                              element={<SkuBasketForm />}
                            />
                          )}
                          {hasAccess("POS Baskets") && (
                            <Route
                              path="/admin/pos-baskets"
                              element={<PosBasketList />}
                            />
                          )}
                          {hasAccess("POS Baskets") && (
                            <Route
                              path="/admin/pos-basket/view/:basketId"
                              element={<PosBasketForm />}
                            />
                          )}
                          {hasAccess("Outlet Research") && (
                            <Route
                              path="/admin/outlet-research"
                              element={<OutletResearchPage />}
                            />
                          )}
                          {hasAccess("Outlet Research") && (
                            <Route
                              path="/admin/outlet-research/edit/:researchId"
                              element={<OutletResearchForm />}
                            />
                          )}
                          {hasAccess("Outlet Research") && (
                            <Route
                              path="/admin/outlet-research/new"
                              element={<OutletResearchForm />}
                            />
                          )}
                          {hasAccess("KT Modules") && (
                            <Route
                              path="/admin/knowledge-transfer"
                              element={<KnowledgeTransferPage />}
                            />
                          )}
                          {hasAccess("KT Modules") && (
                            <Route
                              path="/admin/knowledge-transfer/edit/:moduleId"
                              element={<KnowledgeTransferForm />}
                            />
                          )}
                          {hasAccess("KT Modules") && (
                            <Route
                              path="/admin/knowledge-transfer/new"
                              element={<KTModulesCreation />}
                            />
                          )}
                          {hasAccess("POS Baskets") && (
                            <Route
                              path="/admin/pos-basket/new"
                              element={<PosBasketForm />}
                            />
                          )}
                          {hasAccess("Calls Manager") && (
                            <Route
                              path="/admin/call/new"
                              element={<AddCallForm />}
                            />
                          )}
                          {hasAccess("Calls Manager") && (
                            <Route
                              path="/admin/call/view/:callId"
                              element={<UpdateCall />}
                            />
                          )}
                          {hasAccess("Projects") && (
                            <Route
                              path="/admin/projects"
                              element={<ProjectsPage />}
                            />
                          )}
                           {hasAccess("Projects") && (
                            <Route
                              path="/admin/projects/new"
                              element={<ProjectForm />}
                            />
                          )}
                            {hasAccess("Projects") && (
                            <Route
                              path="/admin/projects/view/:projectId"
                              element={<ProjectForm />}
                            />
                          )}

                          {hasAccess("Regions") && (
                            <Route
                              path="/admin/regions"
                              element={<RegionsPage />}
                            />
                          )}
                          {hasAccess("Roles") && (
                            <Route
                              path="/admin/role/new"
                              element={<AddRoleForm />}
                            />
                          )}
                          {hasAccess("Roles") && (
                            <Route
                              path="/admin/role/view/:roleId"
                              element={<AddRoleForm />}
                            />
                          )}
                          {hasAccess("Diary Imports") && (
                            <Route
                              path="/admin/diary"
                              element={<DiaryImportPage />}
                            />
                          )}
                          {hasAccess("Sales Targets Imports") && (
                            <Route
                              path="/admin/targets"
                              element={<TargetsImportPage />}
                            />
                          )}
                          {hasAccess("Outlet Type") && (
                            <Route
                              path="/admin/outlet-type"
                              element={<OutletTypeList />}
                            />
                          )}
                          {hasAccess("Outlet Type") && (
                            <Route
                              path="/admin/outlet-type/new"
                              element={<OutletTypeForm />}
                            />
                          )}
                          {hasAccess("Outlet Type") && (
                            <Route
                              path="/admin/outlet-type/edit/:outletTypeId"
                              element={<OutletTypeForm />}
                            />
                          )}
                           {hasAccess("Grading") && (
                            <Route
                              path="/admin/grading"
                              element={<GradingList />}
                            />
                          )}
                           {hasAccess("Grading") && (
                            <Route
                              path="/admin/grading/new"
                              element={<GradingForm />}
                            />
                          )}
                          {hasAccess("Grading") && (
                           <Route
                             path="/admin/grading/edit/:gradingId"
                             element={<GradingForm />}
                           />
                         )}
                          {hasAccess("Drop Off") && (
                            <Route
                              path="/admin/dropoff"
                              element={<DropOffPage />}
                            />
                          )}
                          {hasAccess("Client") && (
                            <Route
                              path="/admin/clients"
                              element={<Client />}
                            />
                          )}
                          {hasAccess("Client") && (
                            <Route
                              path="/admin/clients/new"
                              element={<ClientForm />}
                            />
                          )}
                          {hasAccess("Client") && (
                            <Route
                              path="/admin/clients/edit/:clientId"
                              element={<ClientForm />}
                            />
                          )}
                          {hasAccess("Outlet State") && (
                            <Route
                              path="/admin/outletstate"
                              element={<OutletState />}
                            />
                          )}
                            {hasAccess("Outlet State") && (
                            <Route
                              path="/admin/outletstate/new"
                              element={<OutletStateForm />}
                            />
                          )}
                          {hasAccess("Outlet State") && (
                            <Route
                              path="/admin/outletstate/edit/:outletstateId"
                              element={<OutletStateForm />}
                            />
                          )}
                          {hasAccess("Geographical Hierarchy") && (
                            <Route
                              path="/admin/geographical_hierarchy"
                              element={<GeographicalHierarchyHome />}
                            />
                          )}
                        </Route>
                        <Route path="*" element={<Navigate to="/" />} />
                      </Route>
                    </>
                  )}
                  <Route path="/resetpassword" element={<ResetPassword />} />
                  <Route
                    path="/questionnaire/:moduleId"
                    element={<KTModulePublicQuestionnaire />}
                  />
                </Routes>
              </S.SwitchContainer>
            </S.MainContainer>
          </S.Main>
        </AppContext.Provider>
      </Provider>
    </Wrapper>
  );
};

export default App;
